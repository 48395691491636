<template>
  <div class="Home">
    <div class="page-header">
      <div class="page-title">Contact</div>
    </div>
    <div class="main-content">
      <div class="main-content-left">
        <div class="main-content-title">General inquiries</div>
        <div class="main-content-text">
          <p>
            Feel free to send any question you may have when using the MIC (is
            it applicable to your simulation? what data format should you use?
            etc.). Feel also free to send a feedback (whether it is positive or
            not) regarding your experience with the MIC. Please include as many
            details as possible (organizational context, purpose of the
            simulation, data format of the MIC, tools where the MIC is used,
            etc.). Feel finally free to get in touch with us if your company or
            institution would like to collaborate on the definition and
            standardization of the MIC. Our contact adress is :
            <a href="mailto:mic-contact@irt-systemx.fr"
              >mic-contact@irt-systemx.fr</a
            >
          </p>
        </div>
        <div class="main-content-title">
          Feedbacks about the fields of the MIC
        </div>
        <div class="main-content-text">
          <p>
            If you want to send a feedback about specific fields (fields which
            could be improved, fields which are not clear, fields which could be
            added or even removed, etc.), you can use the form below (or our
            contact adress :<a href="mailto:mic-contact@irt-systemx.fr"
              >mic-contact@irt-systemx.fr</a
            >). These feedbacks are particularly important as they are used to
            improve the MIC.
          </p>
        </div>
        <div class="main-content-text">
          <div class="contact-form">
            <div v-if="isSending" class="loading">Sending...</div>
            <div v-if="isSend" class="loading">
              <b>Thank you! Your message has been successfully sent</b>
            </div>
            <div v-if="errorSending" class="loading">
              An error was detected while sending the message. You can contact
              us at :
              <a href="mailto:mic@irt-systemx.fr">mic-contact@irt-systemx.fr</a>
            </div>
            <form class="form" @submit="onSubmit">
              <input
                required
                name="firstName"
                v-model="contactForm.firstName"
                placeholder="First Name *"
                type="text"
                autocomplete="on"
              />
              <input
                required
                name="lastName"
                v-model="contactForm.surname"
                placeholder="Last Name *"
                type="text"
                autocomplete="on"
              />
              <input
                required
                name="email"
                v-model="contactForm.email"
                placeholder="E-mail *"
                type="email"
                autocomplete="on"
              />
              <input
                name="company"
                v-model="contactForm.institution"
                placeholder="Institution / Company"
                type="text"
                autocomplete="on"
              />
              <input
                name="field"
                v-model="contactForm.field"
                placeholder="MIC Field for witch the comment apply (optionnal)"
                type="text"
                autocomplete="on"
              />
              <textarea
                required
                name="message"
                v-model="contactForm.message"
                rows="8"
                placeholder="Message *"
              ></textarea>
              <button class="button">Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentRight from "@/components/ContentRight.vue";
import axios from "axios";

export default {
  name: "MainContent",
  components: {
    ContentRight,
  },
  data() {
    return {
      contactForm: {
        firstName: "",
        surname: "",
        email: "",
        institution: "",
        field: "",
        message: "",
      },
      isSending: false,
      errorSending: false,
      isSend: false,
    };
  },
  methods: {
    resetForm() {
      this.contactForm = {
        firstName: "",
        surname: "",
        email: "",
        institution: "",
        field: "",
        message: "",
      };
    },
    async onSubmit(evt) {
      this.$matomo.trackEvent(
        "Contact",
        "Contact via formulaire",
        "Matomo Event Tracking contact"
      );
      this.errorSending = false;
      this.isSend = false;
      evt.preventDefault();
      //todo validate form
      this.isSending = true;

      let params = { contactForm: this.contactForm };
      try {
        await axios.post("/api/send-email", params);
        this.isSending = false;
        this.isSend = true;
        this.resetForm();
      } catch (error) {
        this.errorSending = true;
        this.isSend = false;
      }

      // send data to backend for sending the email
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
  margin-left: 10%;
  margin-top: 210px;
}
.main-content {
  width: 60%;
  flex: 1;
  margin: 70px 20% 0 20%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
}
.page-header {
  width: 100%;
  background-image: url("~@/assets/images/shutterstock_1318588295.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: -200px;
  height: 390px;
  overflow: hidden;
  position: relative;
}
.main-content-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  letter-spacing: 0;
  color: #333333;
  opacity: 1;
}

.main-content-text {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0;
  color: #666666;
  opacity: 1;
  padding-top: 20px;
}

.main-content-illustration {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.main-content-illustration .sample-wrapper {
  width: 48%;
}

.main-content-illustration .sample-wrapper .sample-image {
  overflow: hidden;
}
.main-content-illustration .sample-wrapper img {
  width: 100%;
  height: 17vw;
}

.illustration-legend {
  text-align: left;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 8px;
  letter-spacing: 0.8px;
  color: #666666;
  width: 332px;
  display: block;
  margin-bottom: 20px;
}

.main-content-left {
  width: 100%;
}

.contact-form {
  font-family: 16px;
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
}

.contact-form .separator {
  border-bottom: solid 1px #ccc;
  margin-bottom: 15px;
}

.contact-form .form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 16px;
}

.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form textarea {
  border: solid 1px #e8e8e8;
  font-family: "Roboto", sans-serif;
  padding: 10px 7px;
  outline: none;
  margin: 1% 2%;
}

input[name="firstName"],
input[name="lastName"] {
  width: 40%;
}

input[name="email"],
input[name="company"],
input[name="field"] {
  width: 86%;
}

.contact-form textarea {
  width: 86%;
}

.contact-form .button {
  background: #da552f;
  border: solid 1px #da552f;
  color: white;
  cursor: pointer;
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
  width: 88%;
  margin: 2%;
}

.contact-form .button:hover {
  background: #ea532a;
  border: solid 1px #ea532a;
}

.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form textarea,
.contact-form .button {
  font-size: 15px;
  border-radius: 3px;
}

.loading {
  font-size: 1.2em;
}
</style>